* {
  font-family: Raleway;
}

/* override chonky search */
.chonky-searchFieldContainer {
  display: none !important;
}

/* override chonky childrenCount and shows without focus */
div[class^="fileIcon"], div[class*="fileIcon"] {
  opacity: 100 !important;
  font-weight: bolder;
}

.wysiwygEditor {
  max-height: 345px;
  padding: 15px;
  border: 1px solid #F1F1F1;
}

.react-tooltip {
  z-index: 99;
}

/* override bootstrap accordion */
.accordion {
  --bs-accordion-active-bg: transparent !important;
  --bs-accordion-bg: transparent !important;;
}