html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Bootstrap 5 tweak: do not underline links unless hovered over */
a:not([class*="btn"]) {
  text-decoration: none;
}
a:not([class*="btn"]):hover {
  text-decoration: underline;
}

/* QC-65762: Temp style fix for spacing on Sidebar until we update qcore */
#app-layout-sidenav {
  button {
    margin-bottom: 0;
    height: 55px;
    &:after {
      height: 55px;
    }
  }
}
